import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json'
import { contractAddresses } from '../web3/contractsAddresses';

import Background from "../components/background";
import Countdown from "../components/countdown";
import MainBoard from "../components/mainBoard";
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';

import { whitelistDB } from "../App";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const FollowLink = styled(Link)`
display:block;
margin-top :30px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const WhiteList = () => {

    Modal.setAppElement('#root');

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);

    const [userBalance, setUserBalance] = useState(false)
    const [txStatus, setTxStatus] = useState('idle');
    const [txError, setTxError] = useState(undefined);
    const [response, setResponse] = useState('');

    const [modalIsOpen, setIsOpen] = useState(false);

    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        LlamaProvider_
    );

    let subtitle;

    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        checkBalance();
    }, [isConnected]);

    useEffect(() => {
        checkBalance();
    }, [address]);

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const checkBalance = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const cydBalance = await cypherDudesReadContract.balanceOf(address);
            const cydBitBalance = await cypherDudesBitReadContract.balanceOf(address);
            const totalBalance = cydBalance + cydBitBalance;
            if (totalBalance > 0) {
                setUserBalance(true)
            }
        } catch (error) {
            setTxError(error);
        }
    }

    const addToWhitelist = async () => {
        if (!isConnected) {
            alert("Connect your wallet !")
            return;
        }
        await checkBalance();
        if (!userBalance) {
            return;
        }
        try {
            const wLAddress = document.getElementById('inputAddress').value;
            openModal();
            const newEntry = { address: wLAddress, id: uuidv4() }
            let i = 0;
            whitelistDB.where("address", "==", newEntry.address)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        i++;
                    })
                    if (i < 1) {
                        whitelistDB.doc(newEntry.id).set(newEntry)
                            .then(result => {
                                document.getElementById('inputAddress').value = '';
                            })
                        setTxStatus('Address successfully added')
                    } else {
                        setTxStatus('Address already whitelisted')
                    }
                })
        } catch (error) {
            setTxStatus('Something went wrong, please try again')
        }

    }

    return (
        <div>
            <MainBoard />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>REQUEST PENDING</h2>
                <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            <div className="CountDownContainer">
                <h1>
                    INFLATABLES launching the 27th on Ordinals. ALLOWLIST SLOTS AVAILABLE FOR CYPHERDUDES HOLDERS !
                </h1>
                <div>
                    <p>Drop here your xVerse ordinal Wallet</p>
                    {
                        !isConnected ? <button className="disabledClaimWordUxBtn">PLEASE CONNECT YOUR WALLET</button> : userBalance ? <div className="adminTools"><input id="inputAddress" className="inputAddress" type="text" /><button className="uxBtn" onClick={addToWhitelist}>ADD TO WHITELIST</button></div> :
                            <button className="disabledClaimWordUxBtn">RESERVED TO CYPHERDUDES HOLDERS</button>
                    }
                </div>
                <FollowLink to="https://twitter.com/CypherDudes" target="_blank">Follow @CYPHERDUDES on X to get all the latest infos</FollowLink>
            </div>

        </div>
    )
}

export default WhiteList;