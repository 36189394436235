import React from "react";
import { useState, useEffect } from "react";
import { concat, ethers, id } from "ethers";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Background from "../components/background";
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import { MerkleTree } from 'merkletreejs';
import { GiConsoleController, GiTrashCan } from "react-icons/gi";

import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherPalacesArtifact from '../web3/abi/CypherPalaces.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { superAdminAddress } from '../web3/adminAddresses';

import { whitelistDB, bip39, bip } from "../App";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import ProgressBar from "../components/progressBar";

const keccak256 = require('keccak256');

const FollowLink = styled(Link)`
display:block;
margin-top :30px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;


const Admin = () => {

    Modal.setAppElement('#root');

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const openProvider = new ethers.BrowserProvider(window.ethereum);
    

    const [userBalance, setUserBalance] = useState(false);
    const [txStatus, setTxStatus] = useState('idle');
    const [mintStatus, setMintStatus] = useState(false);
    const [txError, setTxError] = useState(undefined);
    const [txBeingSent, setTxBeingSent] = useState(undefined);
    const [response, setResponse] = useState('');
    const [whitelist, setWhitelist] = useState([]);
    const [manualWhitelistAddress, setManualWhitelistAddress] = useState('');
    const [WLStartTime, setWLSaleStart] = useState(0);
    const [PublicStartTime, setPublicSaleStart] = useState(0);
    const [currentWLStartTime, setcurrentWLSaleStart] = useState('');
    const [currentPublicStartTime, setcurrentPublicSaleStart] = useState('');
    const [merkleTree, setMerkleTree] = useState('');
    const [count, setCount] = useState('');
    const [supply, setSupply] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [progress, setProgress] = useState(0);
    const [currentWord, setCurrentWord] = useState('');
    const [currentId, setCurrentId] = useState(0);

    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_1 = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");

    const cypherPalacesContract = new ethers.Contract(
        contractAddresses.CypherPalaces,
        CypherPalacesArtifact.abi,
        infuraProvider
    );


    const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

    let subtitle;

    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        getMintStatus();
    }, []);


    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const _dismissTransactionError = () => {
        setTxError(undefined);
    }

    const getMintStatus = async () => {
        const _mintStatus = await cypherPalacesContract.claimStarted();
        setMintStatus(_mintStatus);
    }

    const isSuperAdmin = () => {
        if (isConnected) {
            if (address === superAdminAddress) {
                setSuperAdmin(true);
            } else {
                setSuperAdmin(false);
            }
        }
    }

    const getWLCount = async () => {
        whitelistDB.get().then(function (querySnapshot) {
            setCount(querySnapshot.size);
        })
    }

    const getWhiteList = async () => {
        if (!superAdmin) {
            return;
        }
        try {
            whitelistDB.onSnapshot((querySnapshot) => {
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push(doc.data());
                })
                setWhitelist(items);
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getWhitelistStart = async () => {
        const signer = await provider.getSigner();
        const cypherDudesContract = new ethers.Contract(
            contractAddresses.CypherDudes,
            CypherDudesArtifact.abi,
            signer
        );
        try {
            let _WLStartTime = await cypherDudesContract.wlSaleStartTime();
            let WLdate = new Date(Number(_WLStartTime))
            setcurrentWLSaleStart(WLdate);
        } catch (error) {
            console.log(error)
        }
    }
    const getPublicStart = async () => {
        const signer = await provider.getSigner();
        const cypherDudesContract = new ethers.Contract(
            contractAddresses.CypherDudes,
            CypherDudesArtifact.abi,
            signer
        );
        try {
            let _PStartTime = await cypherDudesContract.publicSaleStartTime();
            let Pdate = new Date(Number(_PStartTime))
            setcurrentPublicSaleStart(Pdate);
        } catch (error) {
            console.log(error)
        }
    }

    const addToWhitelist = async () => {
        if (!superAdmin) {
            return;
        }
        try {
            openModal();
            const newEntry = { address: manualWhitelistAddress, id: uuidv4() }
            if (newEntry.address.match(/^0x[a-fA-F0-9]{40}$/)) {
                let i = 0;
                whitelistDB.where("address", "==", newEntry.address)
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            i++;
                        })
                        if (i < 1) {
                            whitelistDB.doc(newEntry.id).set(newEntry)
                                .then(result => {
                                    document.getElementById('inputAddress').value = '';
                                })
                            setTxStatus('Address successfully added')
                        } else {
                            setTxStatus('Address already whitelisted')
                        }
                    })
            }
        } catch (error) {
            setTxStatus('Something went wrong, please try again');
        } finally {
            setManualWhitelistAddress('')
        }
        setTimeout(getWLCount(), 500);
        getWhiteList();
    }

    const generateMerkleTree = async () => {
        if (!superAdmin) {
            return;
        }
        await getWhiteList();
        try {
            openModal();
            const leaves = whitelist.map(x => keccak256(x.address));
            const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
            const root = '0x' + tree.getRoot().toString('hex');
            console.log(root);
            const signer = await provider.getSigner();
            const cypherDudesContract = new ethers.Contract(
                contractAddresses.CypherDudes,
                CypherDudesArtifact.abi,
                signer
            );
            _dismissTransactionError();
            const tx = await cypherDudesContract.setMerkleRoot(root);
            setTxBeingSent(tx.hash);
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                throw new Error("transaction failed");
            }
            setTxStatus('MerkleRoot successfully stored')
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                return;
            }
            console.log(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    const checkAddressValidity = (manualInputAddress) => {
        if (manualInputAddress.length > 0) {
            if (manualInputAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
                document.getElementById('inputAddress').style.boxShadow = '0 0 10px lime';
                document.getElementById('inputAddress').style.border = 'lime 1px solid';
                setManualWhitelistAddress(manualInputAddress)
            } else {
                document.getElementById('inputAddress').style.boxShadow = '0 0 10px red';
                document.getElementById('inputAddress').style.border = 'red 1px solid';
            }
        } else {
            document.getElementById('inputAddress').style.boxShadow = 'unset';
            document.getElementById('inputAddress').style.border = 'lime 1px solid';
        }

    }

    const setWLStartTime = async () => {
        try {
            openModal();
            const signer = await provider.getSigner();
            const cypherDudesContract = new ethers.Contract(
                contractAddresses.CypherDudes,
                CypherDudesArtifact.abi,
                signer
            );
            _dismissTransactionError();
            const tx = await cypherDudesContract.setWlSaleStart(WLStartTime);
            setTxBeingSent(tx.hash);
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                throw new Error("transaction failed");
            }
            setTxStatus('Public Start time set to ' + WLStartTime.toString())
            getWhitelistStart()
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                return;
            }
            console.log(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    const setPublicStartTime = async () => {
        try {
            openModal();
            const signer = await provider.getSigner();
            const cypherDudesContract = new ethers.Contract(
                contractAddresses.CypherDudes,
                CypherDudesArtifact.abi,
                signer
            );
            _dismissTransactionError();
            const tx = await cypherDudesContract.setPublicSaleStart(PublicStartTime);
            setTxBeingSent(tx.hash);
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                throw new Error("transaction failed");
            }
            setTxStatus('Public Start time set to ' + PublicStartTime.toString())
            getPublicStart();
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                return;
            }
            console.log(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    const removeAddress = async (e) => {
        try {
            whitelistDB.doc(e.target.value).delete();
        } catch (error) {
            console.log(error)
        }
    }

    const withdraw = async () => {
        try {
            openModal();
            const signer = await provider.getSigner();
            const cypherPalacesContract = new ethers.Contract(
                contractAddresses.CypherPalaces,
                CypherPalacesArtifact.abi,
                signer
            );
            _dismissTransactionError();
            const tx = await cypherPalacesContract.withdraw();
            setTxBeingSent(tx.hash);
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                throw new Error("transaction failed");
            }
            setTxStatus('Withdraw successfull')
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                return;
            }
            console.log(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    const toggleClaim = async ()=>{
        try {
            openModal();
            setTxStatus('Calling...')
            const signer = await provider.getSigner();
            const cypherPalacesContract = new ethers.Contract(
                contractAddresses.CypherPalaces,
                CypherPalacesArtifact.abi,
                signer
            );
            _dismissTransactionError();
            
            const tx = await cypherPalacesContract.toggleClaimStatus();
            setTxBeingSent(tx.hash);
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                setTxStatus('transaction failed');
                throw new Error("transaction failed");
            }
            const status = await cypherPalacesContract.claimStarted();
            setTxStatus(`MINT ${status?'OPENED':'CLOSED'}`);
            setMintStatus(!mintStatus);
        } catch (error) {

            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                setTxStatus('Transaction canceled by the user');
                return;
            }
            const decodedError = cypherPalacesContract.interface.parseError(error.data)
            setTxStatus('Transaction failed : ' + decodedError?.args)
            console.log(error);
            setTxError(error);
        } finally {
            setTxBeingSent(undefined);
        }
    }

    const wordCheck = async () => {
              
        try {
            const signer = await provider.getSigner();
            const cypherDudesContract = new ethers.Contract(
                contractAddresses.CypherDudes,
                CypherDudesArtifact.abi,
                signer
            );
            const cypherDudesBitContract = new ethers.Contract(
                contractAddresses.CypherDudesBit,
                CypherDudesBitArtifact.abi,
                signer
            );
            _dismissTransactionError();
            setTokens([]);
            for (let i =0; i < 2048; i++){
                let word;
                if (i < 1728){
                    try{
                        let tokenData = await cypherDudesContract.tokenData(i);
                        word = tokenData[2];
                        //fetchedWords.push(tokenData[2]);
                        //setTokens([...fetchedWords]);
                    } catch (error){
                        console.log(error);
                    }
                } else {
                    try{
                        let tokenData = await cypherDudesBitContract.tokenData(i);
                        word = tokenData[2];
                        //fetchedWords.push(tokenData);
                        //setTokens([...fetchedWords]);
                    } catch (error){
                        console.log(error);
                    }
                }
                if(word.length > 0){
                    const docRef = bip39.doc(word.toLowerCase());
                    const docSnapshot = await docRef.get();
                    if(docSnapshot.exists) {
                        await docRef.update({tokenId: i});
                    } else {
                        console.log(`no match for name ${word} for token ID : ${i}`)
                    }
                }
                setCurrentWord(word);
                setCurrentId(i)
                setProgress((i/2048)*100);
            }
        } catch (error) {
            console.log(error);
            setTxError(error);
        }
    }

    const simpleCheck = async () => {
        let word = "SATOSHI";
        const docRef = bip39.doc(word.toLowerCase());
                const docSnapshot = await docRef.get();
                if(docSnapshot.exists) {
                    await docRef.update({tokenId: 0});
                }
    }

    document.addEventListener("mousemove", function (event) {
        if(document.getElementById("desktopMenu") != null){
            const nav = document.getElementById("navMenu");
            nav.style.transform = "translateY(0)";
            nav.style.transition = "transform 0.3s ease-in-out";
        }
    })

    return (
        <div>
            <Background />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>REQUEST PENDING</h2>
                <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            {isConnected ?
                <div className="AdminContainer">
                    <div>
                        <div className="adminTools">
                            <button onClick={toggleClaim} className="uxBtn">{mintStatus?'CLOSE MINT':'OPEN MINT'}</button>
                        </div>
                        <br />
                        <div className="adminTools">
                            <button onClick={withdraw} className="uxBtn">WITHDRAW</button>
                        </div>
                        {/* <p>{`${currentWord} - CYPHERDUDE #${currentId}`}</p>
                        <br />
                        <ProgressBar progress={progress} /> */}
                    </div>
                </div> :
                <div className="CountDownContainer">
                    <p>
                        Not Connected
                    </p>
                </div>
            }
        </div>
    )
}

export default Admin;