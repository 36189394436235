import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherPalacesArtifact from '../web3/abi/CypherPalaces.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherDudesRendererArtifact from '../web3/abi/CypherdudesRenderer.json';
import CypherDudesBitRendererArtifact from '../web3/abi/CypherdudesBitRenderer.json';
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { ethers } from "ethers";
import html2canvas from "html2canvas";
import Modal from 'react-modal';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const utilities = require("../components/utilities");
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const CypherPalace = ({ palaceId }) => {
    Modal.setAppElement('#root');

    const params = useParams();
    const id = parseInt(params.palaceId);

    const [tokenURI, setTokenURI] = useState({});
    const [tokenName, setTokenName] = useState('');
    // Tickers
    const [currentOwner, setCurrentOwner] = useState({});

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const blastProvider = new ethers.JsonRpcProvider("https://eth-mainnet.public.blastapi.io");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        blastProvider
    );

    const cypherPalacesReadContract = new ethers.Contract(
        contractAddresses.CypherPalaces,
        CypherPalacesArtifact.abi,
        blastProvider
    );

    const cypherDudesRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesRender,
        CypherDudesRendererArtifact.abi,
        blastProvider
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        blastProvider
    );

    const cypherDudesBitRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBitRender,
        CypherDudesBitRendererArtifact.abi,
        blastProvider
    );

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        blastProvider
    );

    const [txStatus, setTxStatus] = useState('idle');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [txError, setTxError] = useState(undefined);
    const [modalLocked, setModalLock] = useState(false);
    const [roomModalIsOpen, setRoomIsOpen] = useState(false);
    const [guestModalIsOpen, setGuestIsOpen] = useState(false);
    const [modModalIsOpen, setModIsOpen] = useState(false);
    const [opType, setOpType] = useState(false);
    const [dudeId, setDudeId] = useState(0);
    const [archivesId, setArchivesId] = useState([])
    const [dudeTokens, setDudeTokens] = useState([]);
    const [privateArchiveModalIsOpen, setPrivateArchiveIsOpen] = useState(false);
    const [chartModalIsOpen, setChartModalIsOpen] = useState(false);
    const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);

    const [evtData, setEvtData] = useState({});
    const [loadSTicker, setLoadSTicker] = useState(false);
    const [storeSTicker, setStoreSTicker] = useState(false);
    const [loadMTicker, setLoadMTicker] = useState(false);
    const [storeMTicker, setStoreMTicker] = useState(false);
    const [mintSTicker, setMintSTicker] = useState(false);
    const [mintMTicker, setMintMTicker] = useState(false);
    const [editTicker, setEditTicker] = useState(false);
    const [removeTicker, setRemoveTicker] = useState(false);
    const [archiveTicker, setArchiveTicker] = useState(false);
    const [refreshTicker, setRefreshTicker] = useState(false);


    const [modTicker, setModTicker] = useState(false);

    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    const customPreviewStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            innerWidth: '95%',
            innerHeight: '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        setTimeout(() => {
            _getTokenURI();
            addModBtn();
        }, 500);

    }, []);

    useEffect(() => {
        if (loadSTicker) {
            setOpType(false);
            openRoomModal();
        }
        setLoadSTicker(false)
    }, [loadSTicker]);

    useEffect(() => {
        if (storeSTicker) {
            setOpType(true);
            openRoomModal();
        }
        setStoreSTicker(false)
    }, [storeSTicker]);

    useEffect(() => {
        if (loadMTicker) {
            loadMemory();
        }
        setLoadMTicker(false)
    }, [loadMTicker]);

    useEffect(() => {
        if (storeMTicker) {
            storeMemory(evtData);
        }
        setStoreMTicker(false)
    }, [storeMTicker]);

    useEffect(() => {
        if (mintSTicker) {
            mintSecretRoomData(evtData);
        }
        setMintSTicker(false)
    }, [mintSTicker]);

    useEffect(() => {
        if (mintMTicker) {
            mintMemory(evtData);
        }
        setMintMTicker(false)
    }, [mintMTicker]);

    useEffect(() => {
        if (editTicker) {
            openGuestModal();
        }
        setEditTicker(false)
    }, [editTicker]);

    useEffect(() => {
        if (removeTicker) {
            removeGuest();
        }
        setRemoveTicker(false)
    }, [removeTicker]);

    useEffect(() => {
        if (archiveTicker) {
            setModalLock(true);
            openModal("Loading the archives minted by this Cypherdudes");
            getArchives();
        }
        setArchiveTicker(false)
    }, [archiveTicker]);

    useEffect(() => {
        if (refreshTicker) {
            refreshGuests();
        }
        setRefreshTicker(false)
    }, [refreshTicker]);

    useEffect(() => {
        if (archivesId.length > 0) {
            setModalLock(false);
            setTxStatus(`${archivesId.length} Archive${archivesId.length > 1 ? 's' : ''} found. You can close this modal, the Archive${archivesId.length > 1 ? 's' : ''} will appear once loaded.`)
            getDudesArchives(archivesId)
        }
    }, [archivesId]);

    useEffect(() => {
        if (modTicker) {
            AutoCam();
        }
        setModTicker(false)
    }, [modTicker])

    useEffect(() => {
        var closeBtn = document.getElementById('closeBtn');
        if (closeBtn != null) {
            closeBtn.style.display = modalLocked ? 'block' : 'hidden';
        }
    }, [modalLocked]);

    function openModal(a) {
        setIsOpen(true);
        setTxStatus(a)
    }
    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }
    function closeModal() {
        setIsOpen(false);
        setModalLock(false);
    }
    function openRoomModal() {
        setRoomIsOpen(true);
    }
    function afterOpenRoomModal() {
        subtitle.style.color = '#00ff00';
    }
    function closeRoomModal() {
        setRoomIsOpen(false);
    }
    function openGuestModal() {
        setGuestIsOpen(true);
    }
    function afterOpenGuestModal() {
        subtitle.style.color = '#00ff00';
    }
    function closeGuestModal() {
        setGuestIsOpen(false);
    }

    function openModModal() {
        setModIsOpen(true);
    }
    function afterOpenModModal() {
        subtitle.style.color = '#00ff00';
    }
    function closeModModal() {
        setModIsOpen(false);
    }

    const _getTokenURI = async () => {
        if (!isConnected) {
            setTokenURI({});
            try {
                console.log(id);
                let tokenURI = await cypherPalacesReadContract.tokenURI(id);
                const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                const palaceData = await cypherPalacesReadContract.palaceData(id);
                setDudeId(Number(palaceData[0]));
                const tokenEntry = {
                    tokenId: id.toString(),
                    name: tokenURIJSONDecoded.name,
                    traits: tokenURIJSONDecoded.attributes,
                    animationURL: animationURL
                }
                setTokenURI(tokenEntry);
                setTokenName(tokenURIJSONDecoded.name);
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                setTokenURI({});
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                try {
                    let tokenURI = await cypherPalacesContract.tokenURI(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const palaceData = await cypherPalacesReadContract.palaceData(id);
                    setDudeId(Number(palaceData[0]));
                    const tokenEntry = {
                        tokenId: id.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry)
                    setTokenName(tokenURIJSONDecoded.name);
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                console.log(error);
                setTxError(error);
            } finally {
            }
        }
    }


    async function captureHTMLToCanvas(htmlContent, id) {
        // Create a hidden container to hold the HTML content
        const container = document.createElement("div");
        container.style.zIndex = "-5"; // Hide the container
        container.style.position = "absolute"; // Avoid affecting layout
        container.style.backgroundColor = "#0000ff";
        container.style.width = "512px"; // Set the desired width (square)
        container.style.height = "512px";

        // Insert the HTML content into the container
        container.innerHTML = htmlContent;
        document.body.appendChild(container);

        // Ensure the content is rendered
        await new Promise(resolve => setTimeout(resolve, 100)); // Delay to allow HTML to render

        console.log(container.innerHTML);

        try {
            // Use html2canvas to capture the container content
            const canvas = await html2canvas(container, {
                width: 512,   // Width of the canvas (square)
                height: 512,  // Height of the canvas (square)
                scale: 1,      // Optionally adjust scale if necessary (e.g., `scale: 2` for higher resolution)
                backgroundColor: null
            });
            // Convert canvas to data URL (base64 image)
            const dataUrl = canvas.toDataURL("image/png");

            // Send the data URL to another function
            loadArchive(dataUrl, id);
        } catch (error) {
            console.error('Error capturing HTML to canvas:', error);
        } finally {
            // Remove the container from the DOM
            document.body.removeChild(container);
        }
    }

    const bin2char = (bin) => {
        return bin.match(/.{1,8}/g)
            .map(bin => String.fromCharCode(parseInt(bin, 2)))
            .join('');
    }
    const char2bin = (char) => {
        return char.split('')
            .map(char => char.charCodeAt(0).toString(2).padStart(8, "0"))
            .join('');
    }

    const fetchArchives = async (id, cursor = null, archives = []) => {
        const search = `- CYD #${id} -`;
        console.log('fetching archives')
        const options = {
            method: 'GET',
            headers: { accept: 'application/json', 'x-api-key': '9a187942ac7a49cfa8a105733fbbe5a9' }
        };
        const baseUrl = 'https://api.opensea.io/api/v2/chain/ethereum/contract/0xe1851e0d09352f2e56171c62c7ded015e199258b/nfts?limit=50'
        try {
            const url = cursor ? `${baseUrl}&next=${cursor}` : baseUrl;
            const response = await fetch(url, options);
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            const nfts = data.nfts || [];
            const filtered = nfts.filter(nft => nft?.name && nft.name.includes(search));
            filtered.forEach(t => {
                archives.push(parseInt(t.identifier))
            });
            if (data.next) {
                return fetchArchives(id, data.next, archives);
            }
            return archives;
        } catch (error) {
            console.error("Error fetching Archives:", error);
            return archives;
        }
    }

    const getArchives = async () => {
        const dudeArchives = await fetchArchives(dudeId);
        setArchivesId(dudeArchives);
    }

    const getDudesArchives = async (Ids) => {
        console.log(Ids);
        for (let i = 0; i < Ids.length; i++) {
            try {
                let archiveData = await cypherDudesArchivesReadContract.archiveData(Ids[i]);
                let _message;
                if (archiveData[5]) {
                    const messageData = await cypherDudesArchivesReadContract.getMessageData(archiveData[2]);
                    _message = await cypherDudesReadContract.decrypt(archiveData[6], messageData[2]);
                    if (dudeId === 397) {
                        _message = _message.replace('svg"', '');
                    }
                    await captureHTMLToCanvas(_message, i)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const loadSecretRoomData = async () => {
        const cypherPalace = document.getElementById("cypherPalace");
        const _inputSecretKey = document.getElementById("inputSecretKey").value;
        closeRoomModal();
        setModalLock(true);
        openModal('Transaction initiated...');
        if (!isConnected) {
            setTxStatus("Connect your wallet !");
            setModalLock(false);
            return;
        } else {
            try {
                setTxStatus(`Loading data for secret room #${evtData.sRoom}`);
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                const data = await cypherPalacesContract.loadSecretRoom(id, evtData.sRoom);
                if (data.length === 2) {
                    setTxStatus(`Nothing stored in Secret Room ${evtData.sRoom} yet`);
                    return;
                }
                const char = await cypherDudesReadContract.decrypt(_inputSecretKey, data);
                const bin = char2bin(char)
                const message = {
                    action: 'loadSecretRoom',
                    content: bin,
                    id: evtData.sRoom
                };
                cypherPalace.contentWindow.postMessage(message, '*');
                setTxStatus(`Data for secret room #${evtData.sRoom} successfully loaded !`);
                setModalLock(false);
                setEvtData({});
            } catch (error) {
                setTxStatus(`You have not been invited to this Cypher Palace`);
                console.log(error);
                setModalLock(false);
                setEvtData({});
                setOpType(false);
            }
        }
    }
    const storeSecretRoomData = async () => {
        const _inputSecretKey = document.getElementById("inputSecretKey").value;
        closeRoomModal();
        setModalLock(true);
        openModal('Transaction initiated...');
        if (!isConnected) {
            setTxStatus("Connect your wallet !");
            setModalLock(false);
            return;
        } else {
            try {
                setTxStatus(`Storing data for secret room #${evtData.sRoom}`);
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                const char = bin2char(evtData.data);
                const hash = await cypherDudesReadContract.encrypt(_inputSecretKey, char);
                const hashedBytes = utilities.stringToBytes(hash)
                const data = await cypherPalacesContract.storeSecretRoom(id, evtData.sRoom, hashedBytes);
                setTxStatus(`Data for secret room #${evtData.sRoom} successfully stored !`);
                setModalLock(false);
                setEvtData({});
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Storage canceled by the user');
                    return;
                }
                setTxStatus(`You have not been invited to this Cypher Palace`);
                console.log(error);
                setModalLock(false);
                setEvtData({});
                setOpType(false);
            }
        }
    }
    const mintSecretRoomData = async (e) => {
        openModal('Archiving Secret Room Data will be implemented soon');
        setModalLock(true);
        setEvtData({});
    }
    const loadMemory = async () => {
        const cypherPalace = document.getElementById("cypherPalace");
        setModalLock(true);
        openModal('Transaction initiated...');
        try {
            setTxStatus(`Loading memory`);
            const data = await cypherPalacesReadContract.loadMemory(id);
            if (data.length === 2) {
                setTxStatus(`No memories stored yet`);
                return;
            }
            const char = await cypherDudesReadContract.decrypt('cypherpalace', data);
            const bin = char2bin(char)
            const message = {
                action: 'loadMemory',
                memory: bin
            };
            cypherPalace.contentWindow.postMessage(message, '*');
            setTxStatus(`Memory successfully loaded !`);
            setModalLock(false);
            setEvtData({});
        } catch (error) {
            setTxStatus(`Error Loading Memory`);
            console.log(error);
            setModalLock(false);
            setEvtData({});
        }
    }
    const storeMemory = async (e) => {
        setModalLock(true);
        openModal('Transaction initiated...');
        if (!isConnected) {
            setTxStatus("Connect your wallet !");
            setModalLock(false);
            return;
        } else {
            try {
                setTxStatus(`Storing Memory`);
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                const char = bin2char(e.data);
                const hash = await cypherDudesReadContract.encrypt('cypherpalace', char);
                const hashedBytes = utilities.stringToBytes(hash)
                const data = await cypherPalacesContract.storeMemory(id, hashedBytes);
                setTxStatus(`Memory successfully stored !`);
                setModalLock(false);
                setEvtData({});
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Storage canceled by the user');
                    return;
                }
                setTxStatus(`Error Storing Data`);
                console.log(error);
                setModalLock(false);
                setEvtData({});
            }
        }
    }
    const mintMemory = async (e) => {
        openModal('Archiving Memory Data will be implemented soon');
        setModalLock(true);
        setEvtData({});
    }
    function loadArchive(canvas, i) {
        const cypherPalace = document.getElementById("cypherPalace");

        const message = {
            action: 'loadArchive',
            archive: canvas,
            id: i
        };
        cypherPalace.contentWindow.postMessage(message, '*');
    }
    const editGuest = async () => {
        const _inputAddress = document.getElementById("inputAddress").value;
        closeGuestModal()
        setModalLock(true);
        openModal('Transaction initiated...');
        if (!isConnected) {
            setTxStatus("Connect your wallet !");
            setModalLock(false);
            return;
        } else {
            try {
                setTxStatus(`Editing Guest #${evtData.guestId}`);
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                await cypherPalacesContract.replaceGuest(id, evtData.guestId, _inputAddress);
                setTxStatus(`Guest #${evtData.guestId} successfully edited !`);
                setModalLock(false);
                setEvtData({});
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Storage canceled by the user');
                    return;
                }
                setTxStatus(`Error Editing Guest`);
                console.log(error);
                setModalLock(false);
                setEvtData({});
            }
        }
    }
    const removeGuest = async () => {
        setModalLock(true);
        openModal('Transaction initiated...');
        if (!isConnected) {
            setTxStatus("Connect your wallet !");
            setModalLock(false);
            return;
        } else {
            try {
                setTxStatus(`Removing Guest #${evtData.guestId}`);
                const signer = await provider.getSigner();
                const cypherPalacesContract = new ethers.Contract(
                    contractAddresses.CypherPalaces,
                    CypherPalacesArtifact.abi,
                    signer
                );
                await cypherPalacesContract.removeGuest(id, evtData.guestId);
                setTxStatus(`Guest #${evtData.guestId} successfully removed !`);
                setModalLock(false);
                setEvtData({});
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Storage canceled by the user');
                    return;
                }
                setTxStatus(`Error Editing Guest`);
                console.log(error);
                setModalLock(false);
                setEvtData({});
            }
        }
    }

    const refreshGuests = async () => {
        const cypherPalace = document.getElementById("cypherPalace");
        const palaceData = await cypherPalacesReadContract.palaceData(id);
        const guestList = [palaceData[4], palaceData[5], palaceData[6], palaceData[7], palaceData[8]];
        const message = {
            action: 'refresh',
            guests: guestList
        };
        cypherPalace.contentWindow.postMessage(message, '*');
    }

    window.addEventListener('loadS', (e) => {
        setEvtData(e.detail);
        setLoadSTicker(true)
    })
    window.addEventListener('storeS', (e) => {
        setEvtData(e.detail);
        setStoreSTicker(true);
    })
    window.addEventListener('mintRoom', (e) => {
        setEvtData(e.detail);
        setMintSTicker(true);
    })
    window.addEventListener('loadMemory', (e) => {
        setEvtData(e.detail);
        setLoadMTicker(true);
    })
    window.addEventListener('storeMemory', (e) => {
        setEvtData(e.detail);
        setStoreMTicker(true);
    })
    window.addEventListener('mintMemory', (e) => {
        setEvtData(e.detail);
        setMintMTicker(true);
    })
    window.addEventListener('loadArchives', async (e) => {
        setEvtData(e.detail);
        setArchiveTicker(true);
    });
    window.addEventListener('editGuest', (e) => {
        setEvtData(e.detail);
        setEditTicker(true);
    })
    window.addEventListener('removeGuest', (e) => {
        setEvtData(e.detail);
        setRemoveTicker(true);
    })
    window.addEventListener('refresh', (e) => {
        setRefreshTicker(true);
    })


    document.addEventListener("mousemove", function (event) {
        const nav = document.getElementById("navMenu");
        if (event.clientY < 50) {
            nav.style.transform = "translateY(0)";
        } else {
            nav.style.transform = "translateY(-100%)";
        }
        nav.style.transition = "transform 0.3s ease-in-out";
    })

    // MODS
    // const iframe = document.getElementById('cypherPalace');
    // iframe.contentWindow.addEventListener("keydown",(event) => {
    //     if (event.key === 'g' || event.key === 'G' ) { // Press "M" to modify the camera animation
    //         console.log('hello')
    //         AutoCam();
    //     }
    // })
    // document.addEventListener('keydown', (event) => {
    //     if (event.key === 'g' || event.key === 'G') {
    //         setModTicker(true);
    //     }
    // });

    const addModBtn = () => {
        const navBar = document.getElementById('desktopMenu');
        if(navBar){
            const btn = document.createElement('button');
            btn.className = 'ModBtn';
            btn.textContent = 'MOD';
            btn.onclick = () => openModModal();
            const lastChild  = navBar.lastElementChild;
            navBar.insertBefore(btn, lastChild);
        }
    }

    

    const AutoCam = () => {
        closeModModal();
        const iframe = document.getElementById('cypherPalace');
        const script = iframe.contentDocument.createElement('script');
        script.textContent = `
            (function(){
                let movingObject, movingGroup;
                let t = 0;
                let posC, tanC;
                let lastQ = new THREE.Quaternion();
                console.log('launching visit');
                let visit = true;
                controls.enabled = false;
                camLocked = true;
                const geomC = new THREE.ConeGeometry(10,25,50);
                movingObject = new THREE.Mesh(geomC, green);
                movingGroup = new THREE.Group();
                scene.add(movingGroup);
                movingGroup.add(movingObject);
                movingGroup.add(camera);
                const camOffset = new THREE.Vector3(0, 0, 25);
                camera.position.add(camOffset);

                window.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape' && camLocked && visit) {
                    console.log('hello')
                        camLocked = false;
                        movingGroup.remove(camera);
                        camera.position.set(0, 0, 0);
                        camera.rotation.set(Math.PI / 2, 0, 0);
                        controls.enabled = true;
                        controls.reset(camera);
                        const wPos = new THREE.Vector3(), offset = new THREE.Vector3();
                        movingGroup.getWorldPosition(wPos);
                        movingGroup.getWorldDirection(offset);
                        controls.yawObject.position.copy(wPos.add(offset.multiplyScalar(50)));
                        controls.yawObject.rotation.set(0, 0, movingGroup.rotation.y, 'XYZ');
                        scene.remove(movingGroup);
                        movingGroup = null;
                        controls.update();
                        controls.lock();
                        visit = false;
                    }
                }, { once: false, passive: true, capture: true });

                const originalAnimate = window.animate;
                window.animate = function(...args) {
                    if(visit){
                        posC = mainCurve.getPoint(t);
                        movingGroup.position.copy(posC);
                        tanC = mainCurve.getTangent(t).normalize();
                        const targetQ = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0), tanC);
                        movingGroup.quaternion.slerp(targetQ, 0.1);
                        lastQ.copy(movingGroup.quaternion);
                        t += 0.0002;
                        if(t>1){t=0};
                    }
                    originalAnimate.apply(this, args);
                }
            })();
        `;
        iframe.contentDocument.body.appendChild(script);
    }


    return (
        <div className="palaceContainer">
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>{txStatus}</h2>
                <button id="closeBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            <Modal
                isOpen={roomModalIsOpen}
                onAfterOpen={afterOpenRoomModal}
                onRequestClose={closeRoomModal}
                style={customStyles}
                contentLabel="Secret Room encryption key">
                <div ref={(_subtitle) => (subtitle = _subtitle)}>
                    <p className="modalInfo">Secret Room data is Private. If you loose the password, you won't be able to recover stored the data.</p>
                    <input id="inputSecretKey" className="inputAddress" type="text" placeholder="type Encryption Key" />
                    {opType ? <button className="uxBtn" onClick={storeSecretRoomData}>STORE</button> : <button className="uxBtn" onClick={loadSecretRoomData}>LOAD</button>}

                </div>
                <br />
                <button className="uxBtn modal" onClick={closeRoomModal}>CANCEL</button>
            </Modal>
            <Modal
                isOpen={guestModalIsOpen}
                onAfterOpen={afterOpenGuestModal}
                onRequestClose={closeGuestModal}
                style={customStyles}
                contentLabel="Secret Room encryption key">
                <div ref={(_subtitle) => (subtitle = _subtitle)}>
                    <p className="modalInfo">Change the guest address</p>
                    <input id="inputAddress" className="inputAddress" type="text" placeholder="type guest address" />
                    <button className="uxBtn" onClick={editGuest}>EDIT</button>
                </div>
                <button className="uxBtn modal" onClick={closeRoomModal}>CANCEL</button>
            </Modal>
            <Modal
                isOpen={modModalIsOpen}
                onAfterOpen={afterOpenModModal}
                onRequestClose={closeModModal}
                style={customStyles}
                contentLabel="CypherPalaces MODS">
                <div ref={(_subtitle) => (subtitle = _subtitle)}>
                    <div className="modList">
                        <button className="uxBtn" onClick={AutoCam}>VISIT</button>
                        <p className="modalInfo">triggers an automatic camera movement accross all the rooms.</p>
                    </div>
                </div>
                <button className="uxBtn modal" onClick={closeRoomModal}>CANCEL</button>
            </Modal>
            <div className='palacePreview'>
                <iframe id="cypherPalace" sandbox="allow-scripts allow-same-origin allow-pointer-lock" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe>
            </div>
        </div>
    )
}

export default CypherPalace;