import React from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { useEffect, useState } from "react";



const CypherMap = () => {
    return (
        <div className='palacePreview'>
<iframe id="cypherMap" sandbox="allow-scripts allow-same-origin allow-pointer-lock" frameBorder="0" src="/cyphermap/cypherMap.html"></iframe>
        </div>
    )
}
export default CypherMap;