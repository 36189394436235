import React from "react";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherDudesRendererArtifact from '../web3/abi/CypherdudesRenderer.json';
import CypherDudesBitRendererArtifact from '../web3/abi/CypherdudesBitRenderer.json';
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { ethers } from "ethers";
import Modal from 'react-modal';
import { GiChart } from "react-icons/gi";
import { v4 as uuidv4 } from 'uuid';
import ChartRenderer from "../components/chartRenderer";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

import { tokenDB } from "../App";

import Background from "../components/background";

const utilities = require("../components/utilities");
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const CypherdudeFS = ({ tokenID }) => {

    Modal.setAppElement('#root');

    const params = useParams();
    const id = params.tokenId;

    const [tokenURI, setTokenURI] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [trackValues, setTrackValues] = useState(0);
    const [secretWord, setSecretWord] = useState('');
    const [decodeTicker, setDecodeTicker] = useState(false);
    const [encodeTicker, setEncodeTicker] = useState(false);
    const [lsbTicker, setLSBTicker] = useState(false);
    const [publicArchiveTicker, setPublicArchiveTicker] = useState(false);
    const [privateArchiveTicker, setPrivateArchiveTicker] = useState(false);
    const [txBeingSent, setTxBeingSent] = useState(undefined);
    const [txError, setTxError] = useState(undefined);
    const [updateResult, setUpdateResult] = useState('');

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const openProvider = new ethers.BrowserProvider(window.ethereum);
    const LlamaProvider_1 = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        infuraProvider
    );

    const cypherDudesRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesRender,
        CypherDudesRendererArtifact.abi,
        infuraProvider
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        infuraProvider
    );

    const cypherDudesBitRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBitRender,
        CypherDudesBitRendererArtifact.abi,
        infuraProvider
    );

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        infuraProvider
    );

    const [txStatus, setTxStatus] = useState('idle');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [archiveModalIsOpen, setArchiveIsOpen] = useState(false);
    const [privateArchiveModalIsOpen, setPrivateArchiveIsOpen] = useState(false);
    const [chartModalIsOpen, setChartModalIsOpen] = useState(false);
    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    const customChartStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            innerWidth: '95%',
            innerHeight : '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() =>{
        let navs = document.getElementsByTagName('nav');
        navs[0].style.display = "none";
    },[]);

    useEffect(() => {
        setTimeout(() => {
            _getTokenURI();
        }, 500);
        
    }, []);

    useEffect(() => {
        if (decodeTicker) {
            decode();
        }
        setDecodeTicker(false)
    }, [decodeTicker])

    useEffect(() => {
        if (encodeTicker) {
            encode();
        }
        setEncodeTicker(false)
    }, [encodeTicker])

    useEffect(() => {
        if (lsbTicker) {
            switchLSB();
        }
        setLSBTicker(false)
    }, [lsbTicker])

    useEffect(() => {
        if (publicArchiveTicker) {
            publicArchiveModal();
        }
        setPublicArchiveTicker(false)
    }, [publicArchiveTicker])

    useEffect(() => {
        if (privateArchiveTicker) {
            privateArchive();
        }
        setPrivateArchiveTicker(false)
    }, [privateArchiveTicker])

    const _dismissTransactionError = () => {
        setTxError(undefined);
    }

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openArchiveModal() {
        setArchiveIsOpen(true);
    }

    function afterOpenArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeArchiveModal() {
        setArchiveIsOpen(false);
    }

    function openPrivateArchiveModal() {
        setPrivateArchiveIsOpen(true);
    }

    function afterOpenPrivateArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closePrivateArchiveModal() {
        setPrivateArchiveIsOpen(false);
    }

    function openChartModal() {
        setChartModalIsOpen(true);
    }

    function afterOpenChartModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeChartModal() {
        setChartModalIsOpen(false);
    }

    const getValueByTraitType = (jsonData, traitType) => jsonData.traits.find(trait => trait.trait_type === traitType)?.value || '';

    const _getTokenURI = async () => {
        if (!isConnected) {
            setTokenURI({});
            if (id < 1728) {
                try {
                    let tokenURI = await cypherDudesReadContract.tokenURI(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let tokenData = await cypherDudesReadContract.tokenData(id);
                    const globalProgression = ethers.toNumber(tokenData[1]);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry);
                    setTokenName(tokenURIJSONDecoded.name);
                    setTrackValues(globalProgression);
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    let tokenURI = await cypherDudesBitReadContract.tokenURI(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let tokenData = await cypherDudesBitReadContract.tokenData(id);
                    const globalProgression = ethers.toNumber(tokenData[1]);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry);
                    setTokenName(tokenURIJSONDecoded.name);
                    setTrackValues(globalProgression);
                } catch (error) {
                    console.log(error)
                }
            }

        } else {
            if (id < 1728) {
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesContract = new ethers.Contract(
                        contractAddresses.CypherDudes,
                        CypherDudesArtifact.abi,
                        signer
                    );
                    try {
                        let tokenURI = await cypherDudesContract.tokenURI(id);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        let tokenData = await cypherDudesContract.tokenData(id);
                        const globalProgression = ethers.toNumber(tokenData[1]);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: id.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        setTokenURI(tokenEntry)
                        setTokenName(tokenURIJSONDecoded.name);
                        setTrackValues(globalProgression);
                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            } else {
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesBitContract = new ethers.Contract(
                        contractAddresses.CypherDudesBit,
                        CypherDudesBitArtifact.abi,
                        signer
                    );
                    try {
                        let tokenURI = await cypherDudesBitContract.tokenURI(id);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        let tokenData = await cypherDudesBitContract.tokenData(id);
                        const globalProgression = ethers.toNumber(tokenData[1]);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: id.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        setTokenURI(tokenEntry)
                        setTokenName(tokenURIJSONDecoded.name);
                        setTrackValues(globalProgression);
                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            }

        }
    }

    function decode() {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var pass = iframeDocument.getElementById('dKey').value;
        decrypt(pass);
    }

    function encode() {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var pass = iframeDocument.getElementById('eKey').value;
        var mess = iframeDocument.getElementById('mess').value;
        var hash = encrypt(pass, mess);
        inscribe(hash);
    }

    const encrypt = async (key, message) => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                const signer = await provider.getSigner();
                const cypherDudesContract = new ethers.Contract(
                    contractAddresses.CypherDudes,
                    CypherDudesArtifact.abi,
                    signer
                );
                const hashed = await cypherDudesContract.encrypt(key, message);
                const hashedBytes = utilities.stringToBytes(hashed)
                return hashedBytes;
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    return;
                }
                console.log(error);
            }
        }
    }

    const inscribe = async (hash) => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                openModal();
                const signer = await provider.getSigner();
                const cypherDudesContract = new ethers.Contract(
                    contractAddresses.CypherDudes,
                    CypherDudesArtifact.abi,
                    signer
                );
                if (address === await cypherDudesContract.ownerOf(id)) {
                    const tx = await cypherDudesContract.writeCard(id, hash);
                    setTxBeingSent(tx.hash);
                    const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('inscription failed');
                        throw new Error("writing message failed");
                    }
                    setTxStatus('Message successfully inscribed on the blockchain');
                    var iframe = document.getElementById('tokenIframe');
                    var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    iframeDocument.getElementById('eKey').value = '';
                    iframeDocument.getElementById('mess').value = '';
                    //updateTokenURI();
                }
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Inscription canceled by the user');
                    return;
                }
                const decodedError = cypherDudesReadContract.interface.parseError(error.data)
                setTxStatus('Inscription failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            } finally {
                setTxBeingSent(undefined);
            }
        }
    }

    const decrypt = async (key) => {
            try {
                const hash = await cypherDudesReadContract.readCard(id);
                const message = await cypherDudesReadContract.decrypt(key, hash);
                var iframe = document.getElementById('tokenIframe');
                var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframeDocument.getElementById('decodedData').innerHTML = message;
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    return;
                }
                console.log(error);
                setTxError(error);
            }
    }

    const switchLSB = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                openModal();
                const signer = await provider.getSigner();
                    const cypherDudesRendererContract = new ethers.Contract(
                    contractAddresses.CypherDudesRenderer,
                    CypherDudesRendererArtifact.abi,
                    signer
                );
                let lsb = await cypherDudesRendererContract.lsb(id);
                lsb = Boolean(lsb)?0:1;
                const tx = await cypherDudesRendererContract.switchLSB(lsb, id);
                    setTxBeingSent(tx.hash);
                    const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('LSB switch failed');
                        throw new Error("LSB switch failed");
                    }
                    setTxStatus('LSB successfully switched');
            } catch (error){
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('LSB switch canceled by the user');
                    return;
                }
                const decodedError = cypherDudesRendererReadContract.interface.parseError(error.data)
                setTxStatus('LSB switch failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            }
        }
    }

    const publicArchive = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                console.log('Public Archive setup ready');
                const signer = await provider.getSigner();
                    const cypherDudesArchivesContract = new ethers.Contract(
                    contractAddresses.CypherDudesArchives,
                    CypherDudesArchivesArtifact.abi,
                    signer
                );
                const _inputSecretKey = document.getElementById("inputSecretKey").value;
                closeArchiveModal();
                openModal();
                const tx = await cypherDudesArchivesContract.mintPublicArchive(id, _inputSecretKey);
                const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('Public Archive mint failed');
                        throw new Error("Public Archive mint failed");
                    }
                    setTxStatus('Public Archive successfully minted');
            } catch (error){
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Public Archive mint canceled by the user');
                    return;
                }
                const decodedError = cypherDudesArchivesReadContract.interface.parseError(error.data)
                setTxStatus('Public Archive mint failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            }
        }
    }

    const publicArchiveModal = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            openArchiveModal();
        }
    }

    const privateArchive = async () => {
        closePrivateArchiveModal();
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                console.log('Private Archive setup ready');
                const signer = await provider.getSigner();
                    const cypherDudesArchivesContract = new ethers.Contract(
                    contractAddresses.CypherDudesArchives,
                    CypherDudesArchivesArtifact.abi,
                    signer
                );
                closePrivateArchiveModal();
                openModal();
                const tx = await cypherDudesArchivesContract.mintPrivateArchive(id);
                const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('Private Archive mint failed');
                        throw new Error("Private Archive mint failed");
                    }
                    setTxStatus('Private Archive successfully minted');
            } catch (error){
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Private Archive mint canceled by the user');
                    return;
                }
                const decodedError = cypherDudesArchivesReadContract.interface.parseError(error.data)
                setTxStatus('Private Archive mint failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            }
        }
    }

    window.addEventListener('decode', () => {
        setDecodeTicker(true)
    })

    window.addEventListener('encode', () => {
        setEncodeTicker(true)
    })

    window.addEventListener('lsb',()=>{
        setLSBTicker(true);
    })

    window.addEventListener('publicArchive',()=>{
        setPublicArchiveTicker(true);
    })

    window.addEventListener('privateArchive',()=>{
        setPrivateArchiveTicker(true);
    })

    return (
        <div>
            <div className='fullScreenContainer'>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Inscription Complete">
                    <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>Transaction initiated...</h2>
                    <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
                </Modal>
                <Modal
                    isOpen={archiveModalIsOpen}
                    onAfterOpen={afterOpenArchiveModal}
                    onRequestClose={closeArchiveModal}
                    style={customStyles}
                    contentLabel="Public Archive Secret Key">
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>
                        <p className="modalInfo">You're about to mint on the blockchain the content of the stored message as a PUBLIC ARCHIVE. Make sure that you're providing the <span style={{textDecoration: "underline"}}>exact same</span> secret key that was used to encrypt the message.</p>
                        <input id="inputSecretKey" className="inputAddress" type="text"/>
                        <button className="uxBtn" onClick={publicArchive}>MINT</button>
                    </div>
                    <button className="uxBtn modal" onClick={closeArchiveModal}>CANCEL</button>
                </Modal>
                <Modal
                    isOpen={privateArchiveModalIsOpen}
                    onAfterOpen={afterOpenPrivateArchiveModal}
                    onRequestClose={closePrivateArchiveModal}
                    style={customStyles}
                    contentLabel="Public Archive Secret Key">
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>
                        <p className="modalInfo">You're about to mint on the blockchain the content of the stored message as a PRIVATE ARCHIVE. the message will be accessible only by entering the secret key.</p>
                        <button className="uxBtn" onClick={privateArchive}>MINT</button>
                    </div>
                    <button className="uxBtn modal" onClick={closePrivateArchiveModal}>CANCEL</button>
                </Modal>
                <Modal
                    isOpen={chartModalIsOpen}
                    onAfterOpen={afterOpenChartModal}
                    onRequestClose={closeChartModal}
                    style={customChartStyles}
                    contentLabel="Token Chart">
                    <div className="chartModal" ref={(_subtitle) => (subtitle = _subtitle)}>
                        <ChartRenderer trackValues={trackValues} tokenId={id}/>
                        <p className="chartModalInfo">Chart showing the progression curve for the encoding capacity of this token.</p>
                        <p className="chartModalInfo">X scale is showing the tupple : Grid size - Grid resolution - Level Progression.</p>
                    </div>
                    <button className="uxBtn modal chartbtn" onClick={closeChartModal}>CLOSE</button>
                </Modal>
                <div className='fullScreenToken'>
                    <iframe id="fullScreenTokenIframe" sandbox="allow-scripts allow-same-origin allow-popups" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe>
                </div>
            </div>
        </div>
    )
}

export default CypherdudeFS;